<template>
  <div class="legendBox_v2">
    <header class="head">
      <span @click="popShow(1)">{{ $t('legend_rule_button') }}</span>
    </header>
    <section class="contentBox">
      <div class="tabBox">
        <p :class="tabType == 0 ? 'actTab' : ''" @click="changeTabType(0)">{{ $t('legend_new_tab1') }}</p>
        <p :class="tabType == 1 ? 'actTab' : ''" @click="changeTabType(1)">{{ $t('legend_new_tab2') }}</p>
      </div>
      <div class="rulesBox">
        <div v-if="tabType == 0" @click="popShow(3)">{{ $t('legend_reward_link1') }}</div>
        <div v-else @click="popShow(2)">{{ $t('legend_reward_link2') }}</div>
      </div>
      <div class="listAllBox" v-if="tabType == 0">
        <div class="listTitleBox">
          <span :class="categoryTabType == 1 ? 'actListTitle' : ''" @click="changeCategoryTab(1)">{{ $t('legend_tab1') }}</span>
          <span :class="categoryTabType == 2 ? 'actListTitle' : ''" @click="changeCategoryTab(2)">{{ $t('legend_tab2') }}</span>
          <span :class="categoryTabType == 3 ? 'actListTitle' : ''" @click="changeCategoryTab(3)">{{ $t('legend_tab3') }}</span>
        </div>
        <div class="rankList" @scroll="pageScroll">
          <template v-if="rankListData.length > 0">
            <div class="rankItemA">
              <div class="itemAInfo"  v-for="(item,index) in rankListData" :key="index">
                <div class="rankNum">{{ item.top }}</div>
                <div class="rankMsg">
                  <p class="guideName">
                    <span>{{ item.gname }}</span>
                    <span>id:{{ item.gid }}</span>
                  </p>
                  <p>{{ $t('legend_tab_title_coins') }}：{{ addCommasToNumber(item.pt) }}</p>
                  <p>{{ $t('legend_tab_title_rewards') }}：
                    <span v-if="item.rewardType == 2">{{ item.reward ? langs == 'ar' ? item.reward + '$' : '$'+item.reward : ' '  }} </span>
                    <span v-else-if="item.rewardType == 1">{{ item.reward ? formatNumberK(item.reward ) : ' '}} </span>
                    <span v-else> -- </span>
                  </p>
                </div>
                <div v-if="rankListData.length > 7 && index == 6" class="top7reward">{{ $t('legend_categoryc_top7reward') }}</div>
              </div>
            </div>
          </template>
          <div v-else class="loadingBox">
            <van-loading type="spinner" size="24px"/>
          </div>
        </div>
      </div>
      <div class="totalRankBox listAllBox" v-if="tabType == 1">
        <template v-if="rankListData.length > 0">
          <div class="itemAInfo"   v-for="(item,index) in rankListData" :key="index">
            <div class="rankNum" :class="item.top == 1 ? 'bigMarginTop' : ''">{{ item.top }}</div>
            <div class="rankMsg">
              <p class="guideName">
                <span>{{ item.gname }}</span>
                <span>id:{{ item.gid }}</span>
              </p>
              <p>{{ $t('legend_tab_title_coins') }}：{{ addCommasToNumber(item.pt) }}</p>
              <p v-if="item.vip4 || item.vip5 || item.vip6">{{ $t('legend_freevip_new') }}: {{  item.vip4 ? 'VIP4*' + item.vip4 : '' }} {{ item.vip5 ? ', VIP5*' +  item.vip5 : '' }} {{ item.vip6 ? ', VIP6*' +  item.vip6 : '' }}</p>
              <p v-else>{{ $t('legend_freevip_new') }}: --</p>
              <p v-if="item.top == 1">{{ $t('legend_appsponsor') }}</p>
            </div>
          </div>
        </template>
          <div v-else class="loadingBox">
            <van-loading type="spinner" size="24px"/>
          </div>
      </div>
    </section>
    <!-- 弹窗部分 -->
    <div class="popBox" v-if="popFlag > 0">
      <div class="closeBox" @click="popShow(0)">
        <span class="closeBtn"></span>
      </div>
      <div class="eventRulesBox" v-if="popFlag == 1">
        <div class="rulesMsgBox">
          <p><span>1.</span><span>{{ $t('legend_rule_row1') }}</span></p>
            <p><span>2.</span><span>{{ $t('legend_rule_row2') }}</span></p>
            <p><span>3.</span><span>{{ $t('legend_rule_row3') }}</span></p>
            <p><span>4.</span><span>{{ $t('legend_rule_row4') }}</span></p>
            <p><span>5.</span><span>{{ $t('legend_rule_row5') }}</span></p>
            <p><span>6.</span><span>{{ $t('legend_rule_row6') }}</span></p>
            <p><span>7.</span><span>{{ $t('legend_rule_row7') }}</span></p>
            <p><span>8.</span><span>{{ $t('legend_rule_row8') }}</span></p>
        </div>
      </div>
      <div class="agencyPop" v-if="popFlag == 2">
        <div class="rulesTableBox">
            <header>{{ $t('legend_rule_vipplan') }}</header>
            <div class="rulesTableItem">
              <div class="rulesTableTitle">
                <span>{{ $t('legend_rule_vipplan_title1') }}</span>
                <span>{{ $t('legend_rule_vipplan_title2') }}</span>
                <span>{{ $t('legend_rule_vipplan_title3') }}</span>
                <span>{{ $t('legend_rule_vipplan_title4') }}</span>
              </div>
              <div class="rulesTableBody">
                <span>2,000,000</span>
                <span>1</span>
                <span>-</span>
                <span>-</span>
              </div>
              <div class="rulesTableBody">
                <span>3,000,000</span>
                <span>1</span>
                <span>1</span>
                <span>-</span>
              </div>
              <div class="rulesTableBody">
                <span>5,000,000</span>
                <span>2</span>
                <span>1</span>
                <span>-</span>
              </div>
              <div class="rulesTableBody">
                <span>8,000,000</span>
                <span>3</span>
                <span>2</span>
                <span>-</span>
              </div>
              <div class="rulesTableBody">
                <span>10,000,000</span>
                <span>3</span>
                <span>2</span>
                <span>1</span>
              </div>
            </div>
            <footer>{{ $t('legend_rule_vipplan_note') }}</footer>
         </div>
      </div>
      <div class="categoryAbc giftBox" v-if="popFlag == 3">
        <div class="rulesScrBox">
          <header></header>
          <div class="giftListBox">
            <div class="giftItem" v-for="(item,index) in rewardsData" :key="index">
              <p>{{ item.title }}</p>
              <div class="giftTableTitle">
                <span>{{ $t('legend_reward_title1') }}</span>
                <span>{{ $t('legend_reward_title2') }}</span>
                <span>{{ $t('legend_reward_title3') }}</span>
                <span>{{ $t('legend_reward_title4') }}</span>
              </div>
              <div class="giftTableBody" v-for="(subItem,i) in item.data" :key="i">
                <span>{{ subItem.rank }}</span>
                <span>{{ subItem.rewards }}</span>
                <span>{{ subItem.minimumTarget }}</span>
                <span>{{ subItem.note }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 
</template>
 
<script>
import { Toast } from "vant";
export default {
  name: "legend_v2",
  data() {
    return {
      tabType: 0,
      categoryTabType: 1,
      popFlag:0,
      rewardsData:[
        {
          title:this.$t('legend_tab1'),
          data:[
            {
              rank:'01',
              rewards:this.$t('legend_reward_dollar_1'),
              minimumTarget :this.$t('legend_reward_coins_1'),
              note :this.$t('legend_reward_note1'),
            },{
              rank:'02',
              rewards:this.$t('legend_reward_dollar_3'),
              minimumTarget :this.$t('legend_reward_coins_2'),
              note :this.$t('legend_reward_note1'),
            },{
              rank:'03',
              rewards:this.$t('legend_reward_diamonds_1'),
              minimumTarget :this.$t('legend_reward_coins_3'),
              note :this.$t('legend_reward_note2'),
            },{
              rank:'04',
              rewards:this.$t('legend_reward_diamonds_1'),
              minimumTarget :this.$t('legend_reward_coins_3'),
              note :this.$t('legend_reward_note2'),
            },{
              rank:'05',
              rewards:this.$t('legend_reward_diamonds_4'),
              minimumTarget :this.$t('legend_reward_coins_3'),
              note :this.$t('legend_reward_note2'),
            },{
              rank:'06',
              rewards:this.$t('legend_reward_diamonds_4'),
              minimumTarget :this.$t('legend_reward_coins_3'),
              note :this.$t('legend_reward_note2'),
            },{
              rank:'07',
              rewards:this.$t('legend_reward_diamonds_4'),
              minimumTarget :this.$t('legend_reward_coins_3'),
              note :this.$t('legend_reward_note2'),
            }
          ]
        },
        {
          title:this.$t('legend_tab2'),
          data:[
            {
              rank:'01',
              rewards:this.$t('legend_reward_dollar_2'),
              minimumTarget :this.$t('legend_reward_coins_2'),
              note :this.$t('legend_reward_note1'),
            },{
              rank:'02',
              rewards:this.$t('legend_reward_dollar_4'),
              minimumTarget :this.$t('legend_reward_coins_3'),
              note :this.$t('legend_reward_note1'),
            },{
              rank:'03',
              rewards:this.$t('legend_reward_diamonds_2'),
              minimumTarget :this.$t('legend_reward_coins_4'),
              note :this.$t('legend_reward_note2'),
            },{
              rank:'04',
              rewards:this.$t('legend_reward_diamonds_2'),
              minimumTarget :this.$t('legend_reward_coins_4'),
              note :this.$t('legend_reward_note2'),
            },{
              rank:'05',
              rewards:this.$t('legend_reward_diamonds_5'),
              minimumTarget :this.$t('legend_reward_coins_4'),
              note :this.$t('legend_reward_note2'),
            },{
              rank:'06',
              rewards:this.$t('legend_reward_diamonds_5'),
              minimumTarget :this.$t('legend_reward_coins_4'),
              note :this.$t('legend_reward_note2'),
            },{
              rank:'07',
              rewards:this.$t('legend_reward_diamonds_5'),
              minimumTarget :this.$t('legend_reward_coins_4'),
              note :this.$t('legend_reward_note2'),
            }
          ]
        },
        {
          title:this.$t('legend_tab3'),
          data:[
            {
              rank:'01',
              rewards:this.$t('legend_reward_dollar_3'),
              minimumTarget :this.$t('legend_reward_coins_3'),
              note :this.$t('legend_reward_note1'),
            },{
              rank:'02',
              rewards:this.$t('legend_reward_dollar_5'),
              minimumTarget :this.$t('legend_reward_coins_4'),
              note :this.$t('legend_reward_note1'),
            },{
              rank:'03',
              rewards:this.$t('legend_reward_diamonds_3'),
              minimumTarget :this.$t('legend_reward_coins_5'),
              note :this.$t('legend_reward_note2'),
            },{
              rank:'04',
              rewards:this.$t('legend_reward_diamonds_3'),
              minimumTarget :this.$t('legend_reward_coins_5'),
              note :this.$t('legend_reward_note2'),
            },{
              rank:'05',
              rewards:this.$t('legend_reward_diamonds_6'),
              minimumTarget :this.$t('legend_reward_coins_5'),
              note :this.$t('legend_reward_note2'),
            },{
              rank:'06',
              rewards:this.$t('legend_reward_diamonds_6'),
              minimumTarget :this.$t('legend_reward_coins_5'),
              note :this.$t('legend_reward_note2'),
            },{
              rank:'07',
              rewards:this.$t('legend_reward_diamonds_6'),
              minimumTarget :this.$t('legend_reward_coins_5'),
              note :this.$t('legend_reward_note2'),
            }
          ]
        }
        ],
      // 避免重复请求
      notFast: true,
      page:0,
      round:1,
      size:300,
      // 避免重复请求
      sendFlag: true,
      rankListData:[],
      vipData:[]
    }
  },
  created() { 
    //调用原生方法
    this.$completes.complete("getUserInfo");
    //原生调用h5方法
    window.getUserInfo = this.getUserInfo;
    window["getUserInfo"] = (data) => {
      this.getUserInfo(data);
    };

    // this.getUserInfo() 
    
  },
  methods: {
    //分页
    pageScroll(e) {
      const { scrollTop, scrollHeight, clientHeight } = e.target;
      // 检查是否滚动到底部
      if (scrollTop + clientHeight >= scrollHeight) {
          if(this.notFast){
              this.page++;
              this.getActInfo();
          }
      }
    },
    getUserInfo(data) {
      this.userInfo = JSON.parse(data);
      this.token = JSON.parse(data).token
      this.uid = JSON.parse(data).userId
      let langs = this.userInfo.lang.substring(0,2)
      this.langs = this.userInfo.lang.substring(0,2)
      let lang = this.userInfo.lang
      this.headers = {
          fid:  this.userInfo.fid,
          os:  this.userInfo.os || 1,
          lang: this.userInfo.lang,
          version:this.userInfo.version,
          deviceid:this.userInfo.deviceId,
        }
      //    this.token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiJOemt6TWpJNCIsInh5eiI6IlEwWkRRakJCTURBdFJEYzJOaTAwTURZMkxUaEJRamd0UkVaR01rVXhSRFpCUWpjMSIsImV4cCI6MTk5MjQ5NDQ0N30.toInbRHaQKUfK73mTeDuSXyKmY-dEUZRiMKa_G7JNmw';
      // this.uid = '793228'; 
      // this.headers = {
      //     fid: '1001',
      //     os: 1,
      //     lang: 'zh-CN',
      //     version: '3.0',
      //     deviceid: 'CFCB0A00-D766-4066-8AB8-DFF2E1D6AB75',
      // }
      // let langs = 'en'
      // this.langs = langs
      // let lang = langs
      if (langs == "ar") {
          this.$i18n.locale = "ar";
      } else if (langs == "tr") {
          this.$i18n.locale = "tr";
      } else if (langs == "es") {
          this.$i18n.locale = "es";
      } else if (langs == "pt") {
          this.$i18n.locale = "pt";
      } else {
          this.$i18n.locale = "en";
      }
      document.title = this.$t('legend_title')
      document.querySelector("html").setAttribute("lang", this.$i18n.locale);
      this.getActInfo();
    },
    // 获取活动信息
    async getActInfo(){
      // 获取活动时间
      let personLeveldatas = {
        option: "post",
        host:
          this.$serviceIpJava +
          "api/code/legend/list?token=" +
          this.token +
          "&uid=" +
          this.uid,
        data: {
          page:this.page,
          round:this.round,
          size:this.size,
          type: this.categoryTabType
        },
        lang: this.langs,
        header: this.headers,
      };

      if(this.sendFlag){
          this.sendFlag = false;
          this.notFast = false;
          let data =  await this.$axios.api(personLeveldatas.host,personLeveldatas.data,personLeveldatas.option,personLeveldatas.header)
          if (data.code == 200) {
            this.rankListData.push(...data.data.list);
            console.log(this.rankListData)
            //  是否还有下一页
            if(data.data.list.length == data.data.size){
              this.notFast = true
            }
            if(this.categoryTabType == 0){
              this.getVipInfo()
            }
          }else{
            Toast(data.message);
          }
        this.sendFlag = true;
      }
      
    },
    // 过去vip数据
    async getVipInfo(){
      // 获取活动时间
      let personLeveldatas = {
        option: "get",
        host:
          this.$serviceIpJava +
          "api/code/legend/cfg?token=" +
          this.token +
          "&uid=" +
          this.uid,
        data: {
        },
        lang: this.langs,
        header: this.headers,
      };
      let data =  await this.$axios.api(personLeveldatas.host,personLeveldatas.data,personLeveldatas.option,personLeveldatas.header)
      this.vipData = data.data.totalRankVips

      this.vipData.forEach((item, index, arr) => {
            item.score = Number(item.score)
      });
      // 为表单信息的每一项添加vip等级
      this.rankListData = this.rankListData.map((item)=>{
        return {...item,...this.countVip(item.pt)}
      })
    },
    countVip(pt){
      // 根据pt取出比pt小的
        let data = this.vipData.filter(element => {
            return element.score < Number(pt)
        })
        // 取出其中最大的
        const maxScoreItem = data.reduce((acc, item) => 
          item.score > acc.score ? item : acc, 
          { score: -Infinity } // 初始值
        );
        let vipLevel = {}
        // 为对象赋值 切割数组
        if(maxScoreItem.vips){
          maxScoreItem.vips.split(',').map((e)=> e.split(':')).map(e=>{
            // vipLevel['vip' + e[0]] = vipLevel['VIPS' + e[0]] + '*' + Number(e[1])
            vipLevel['vip' + e[0]] =  Number(e[1])
          })
        }
        return vipLevel
    },
    addCommasToNumber(num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    // 切换tab
    changeTabType(type){
      this.tabType = type;
      this.rankListData = [];
      if(type == 1){
        this.categoryTabType = 0;
      }else{
        this.categoryTabType = 1;
      }
      this.getActInfo();
    },
    // 切换abc
    changeCategoryTab(type){
      this.rankListData = [];
      this.categoryTabType = type;
      this.getActInfo();
    },
    // 弹窗展示
    popShow(type){
      this.popFlag = type;
      if(type == 3){
        this.rewardsData = [
          {
            title:this.$t('legend_tab1'),
            data:[
              {
                rank:'01',
                rewards:this.$t('legend_reward_dollar_1'),
                minimumTarget :this.$t('legend_reward_coins_1'),
                note :this.$t('legend_reward_note1'),
              },{
                rank:'02',
                rewards:this.$t('legend_reward_dollar_3'),
                minimumTarget :this.$t('legend_reward_coins_2'),
                note :this.$t('legend_reward_note1'),
              },{
                rank:'03',
                rewards:this.$t('legend_reward_diamonds_1'),
                minimumTarget :this.$t('legend_reward_coins_3'),
                note :this.$t('legend_reward_note2'),
              },{
                rank:'04',
                rewards:this.$t('legend_reward_diamonds_1'),
                minimumTarget :this.$t('legend_reward_coins_3'),
                note :this.$t('legend_reward_note2'),
              },{
                rank:'05',
                rewards:this.$t('legend_reward_diamonds_4'),
                minimumTarget :this.$t('legend_reward_coins_3'),
                note :this.$t('legend_reward_note2'),
              },{
                rank:'06',
                rewards:this.$t('legend_reward_diamonds_4'),
                minimumTarget :this.$t('legend_reward_coins_3'),
                note :this.$t('legend_reward_note2'),
              },{
                rank:'07',
                rewards:this.$t('legend_reward_diamonds_4'),
                minimumTarget :this.$t('legend_reward_coins_3'),
                note :this.$t('legend_reward_note2'),
              }
            ]
          },
          {
            title:this.$t('legend_tab2'),
            data:[
              {
                rank:'01',
                rewards:this.$t('legend_reward_dollar_2'),
                minimumTarget :this.$t('legend_reward_coins_2'),
                note :this.$t('legend_reward_note1'),
              },{
                rank:'02',
                rewards:this.$t('legend_reward_dollar_4'),
                minimumTarget :this.$t('legend_reward_coins_3'),
                note :this.$t('legend_reward_note1'),
              },{
                rank:'03',
                rewards:this.$t('legend_reward_diamonds_2'),
                minimumTarget :this.$t('legend_reward_coins_4'),
                note :this.$t('legend_reward_note2'),
              },{
                rank:'04',
                rewards:this.$t('legend_reward_diamonds_2'),
                minimumTarget :this.$t('legend_reward_coins_4'),
                note :this.$t('legend_reward_note2'),
              },{
                rank:'05',
                rewards:this.$t('legend_reward_diamonds_5'),
                minimumTarget :this.$t('legend_reward_coins_4'),
                note :this.$t('legend_reward_note2'),
              },{
                rank:'06',
                rewards:this.$t('legend_reward_diamonds_5'),
                minimumTarget :this.$t('legend_reward_coins_4'),
                note :this.$t('legend_reward_note2'),
              },{
                rank:'07',
                rewards:this.$t('legend_reward_diamonds_5'),
                minimumTarget :this.$t('legend_reward_coins_4'),
                note :this.$t('legend_reward_note2'),
              }
            ]
          },
          {
            title:this.$t('legend_tab3'),
            data:[
              {
                rank:'01',
                rewards:this.$t('legend_reward_dollar_3'),
                minimumTarget :this.$t('legend_reward_coins_3'),
                note :this.$t('legend_reward_note1'),
              },{
                rank:'02',
                rewards:this.$t('legend_reward_dollar_5'),
                minimumTarget :this.$t('legend_reward_coins_4'),
                note :this.$t('legend_reward_note1'),
              },{
                rank:'03',
                rewards:this.$t('legend_reward_diamonds_3'),
                minimumTarget :this.$t('legend_reward_coins_5'),
                note :this.$t('legend_reward_note2'),
              },{
                rank:'04',
                rewards:this.$t('legend_reward_diamonds_3'),
                minimumTarget :this.$t('legend_reward_coins_5'),
                note :this.$t('legend_reward_note2'),
              },{
                rank:'05',
                rewards:this.$t('legend_reward_diamonds_6'),
                minimumTarget :this.$t('legend_reward_coins_5'),
                note :this.$t('legend_reward_note2'),
              },{
                rank:'06',
                rewards:this.$t('legend_reward_diamonds_6'),
                minimumTarget :this.$t('legend_reward_coins_5'),
                note :this.$t('legend_reward_note2'),
              },{
                rank:'07',
                rewards:this.$t('legend_reward_diamonds_6'),
                minimumTarget :this.$t('legend_reward_coins_5'),
                note :this.$t('legend_reward_note2'),
              }
            ]
          }
        ]
      }
    },
    formatNumberK(num) {
        if (num >= 1000) {
          return Math.trunc((num / 1000) * 10) / 10 + this.$t('legend_kdiamonds');
        } else {
            return num.toString ();
        }
    },
  }
};
</script>

<style scoped>
@import './index.css';

</style>
